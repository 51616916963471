<template>
  <div class="navbar-container main-menu-content">
    <!-- Menu Gauche -->
    <horizontal-nav-menu-items :items="navMenuItems" />
    <!-- Logo central -->
    <app-navbar-horizontal-layout-brand />
    <!-- Compte Droite -->
    <b-navbar-nav
      class="nav align-items-center ml-auto"
      style="margin-right: 42px"
    >
      <!-- 
        => COMPOSANT UTILISABLE A L'AVENIR
        <search-bar />
        <cart-dropdown />
        <notification-dropdown /> 
        <dark-Toggler class="d-none d-lg-block" />
        <locale />
      -->
      <user-dropdown />
      <!-- <UserDropdown :items="navMenuItemsLeft" class="left" /> -->
      <horizontal-nav-menu-items :items="navMenuItemsLeft" class="left" />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import store from "@/store";

import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";
import AppNavbarHorizontalLayoutBrand from "@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue";

import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue";
import UserDropdown from "@/@core/layouts/components/app-navbar/components/UserDropdown.vue";

import { mapGetters, mapActions } from "vuex";
import jwt from "@/auth/jwt/useJwt";
//import { menuLeft } from "@/data/menu";
// => COMPOSANT UTILISABLE A L'AVENIR
// import Bookmarks from '@/@core/layouts/components/app-navbar/components/Bookmarks.vue'
// import DarkToggler from 'components/DarkToggler.vue'
// import SearchBar from '@/@core/layouts/components/app-navbar/components/SearchBar.vue'
// import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
// import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,

    HorizontalNavMenuItems,
    AppNavbarHorizontalLayoutBrand,

    Locale,
    UserDropdown,
  },

  setup() {
    return {};
  },
  computed: {
    ...mapGetters(["workspaceSelected", "institution", "institutionsList"]),
    navMenuItems() {
      let navMenuItems = [
        {
          title: "Accueil",
          route: "home",
          icon: "dashboard",
          iconType: "google",
        },
        {
          title: "Interventions",
          icon: "assignment",
          iconType: "google",
          // disable: true,
          children: [
            {
              type: "divider",
              title: "Intervention",
              class: "mt-1",
            },
            {
              title: "Toutes les interventions",
              route: "interventions",
            },
            {
              type: "divider",
              title: "Étape courantes",
              route: "types-devices",
            },
            {
              title: "Liste des étapes",
              route: "commonStep",
            },
            {
              type: "divider",
              title: "Tâche courantes",
              route: "types-devices",
            },
            {
              title: "Liste des tâches",
              route: "commonTask",
            },
          ],
        },
        {
          title: "Entreprises",
          icon: "people_alt",
          iconType: "google",
          children: [
            {
              type: "divider",
              title: "Annuaire",
              class: "mt-1",
            },
            {
              title: "Collaborateurs",
              route: "collaborators",
            },
            {
              title: "Clients",
              route: "customers",
            },
            {
              type: "divider",
              title: "Application",
              class: "mt-1",
            },
            {
              title: "Utilisateurs",
              route: "users",
            },
          ],
        },
        {
          title: "Bassins",
          icon: "pool",
          iconType: "google",
          route: "pools",
        },
        {
          title: "Produits",
          icon: "water_drop",
          iconType: "google",
          route: "products",
        },
        {
          title: "Appareils",
          icon: "cleaning_services",
          iconType: "google",
          route: "device",
        },
        // {
        //   title: "GED",
        //   icon: "move_to_inbox",
        //   iconType: "google",
        //   route: "ged",
        // },
      ];
      
      if (this.workspaceSelected.modules) {
        for (let i = 0; i < navMenuItems.length; i++) {
          const item = navMenuItems[i];
          if (item.title == "Accueil") {
            for (let j = 0; j < item.children.length; j++) {
              const child = item.children[j];
              if (
                child.title == "Tableau de bord Analytique" &&
                !this.workspaceSelected.modules.find(
                  (el) => el.label == "Facturation"
                )
              ) {
                item.children.splice(j, 1);
                j--;
              } else if (
                child.title == "Tableau de bord Gestion" &&
                !this.workspaceSelected.modules.find((el) => el.label == "Baux")
              ) {
                item.children.splice(j, 1);
                j--;
              }
            }
          } else if (item.title == "Entreprises") {
            let companyChildrenModuleTitles = "Utilisateurs";
            for (let j = 0; j < item.children.length; j++) {
              const subItem = item.children[j];
              if (
                subItem.title == companyChildrenModuleTitles &&
                !this.userRolesLocal.includes("Admin")
              ) {
                item.children.splice(j, 1);
                j--;
              }
            }
          }
        }
      }
      return navMenuItems;
    },
    navMenuItemsLeft() {
      let menu = [
        {
          title: "Paramètres",
          icon: "settings",
          iconType: "google",
          children: [
            {
              type: "divider",
              title: "Bassin",
              class: "mt-1",
            },
            {
              title: "Types de bassins",
              route: "types-bassins",
            },
            {
              title: "Types de revêtements",
              route: "types-revêtements",
            },
            {
              title: "Types de traitements",
              route: "types-traitements",
            },
            {
              title: "Méthodes d'accès au bassin",
              route: "type-methode-acces",
            },
            {
              title: "Types d'accès au bassin",
              route: "type-acces",
            },
            {
              title: "Types de contrats de maintenances",
              route: "types-contrats-maintenances",
            },
            {
              type: "divider",
              title: "Intervention",
              class: "mt-1",
            },
            {
              title: "Types d'interventions",
              route: "types-interventions",
            },
            // {
            //   title: "Types de tâches",
            //   route: "types-tâches",
            // },
            {
              type: "divider",
              title: "Appareils",
              route: "types-devices",
            },
            {
              title: "Types d'appareils",
              route: "types-devices",
            },
            {
              title: "Marques d'appareils",
              route: "brand",
            },
            {
              type: "divider",
              title: "Utilisateurs",
              route: "types-devices",
            },
            {
              title: "Types de contrats",
              route: "type-contrats-professionnels",
            },
            {
              title: "Métiers",
              route: "jobs",
            },
          ],
        },
      ];

      let navMenuItems = JSON.parse(JSON.stringify(menu));

      for (let i = 0; i < menu[0].children.length; i++) {
        const element = menu[0].children[i];
        if (element.route === "login") {
          this.$store.dispatch("logOut");
        }
      }

      return navMenuItems;
    },
    getUserInfo() {
      let info = JSON.parse(localStorage.getItem("userInfo"));
      return info;
    },
    getCurrentWorkspace() {
      return this.$store.getters["workspaceSelected"];
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>

<template>
  <li
    v-if="canViewHorizontalNavMenuLink(item) && verifRole(item)"
    :class="{
      'disabled': item.disabled,
      'border-0' : item.type
    }"
  > 
    <!-- <b-dropdown-divider v-if="item.type && item.type=='divider'"></b-dropdown-divider> -->
    <vs-divider v-if="item.type && item.type=='divider' && !item.title" color="#cccccc"class="m-0" :class="item.class"></vs-divider>
    <vs-divider v-else-if="item.type && item.type=='divider'" color="#cccccc" class="m-0 text-primary" position="left"  :class="item.class"><span class="text-primary">{{item.title}}</span></vs-divider>
    <b-link
      v-else-if="item.path"
      :to="{path:'/'+item.path, params: item.params}"
      class="dropdown-item"
    >
      <span class="menu-title">
        <feather-icon
          v-if="item.icon"
          :icon="item.icon"
          class="m-0 mr-50"
          size="16"
        />
        {{ t(item.title) }}
      </span>
    </b-link>

    <b-link v-else-if="item.name && item.params"
      :to="{name:item.name, params: item.params}"
      class="dropdown-item"
    >
      <span class="menu-title">
        <feather-icon
          v-if="item.icon"
          :icon="item.icon"
          class="m-0 mr-50"
          size="16"
        />
        {{ t(item.title) }}
      </span>
      <span v-if="item.badge" class="menu-title badge font-smaller" :class="'bg-'+item.badgeColor">{{t(item.badge)}}</span>
    </b-link>
    <b-link v-else
     v-bind="linkProps"
      class="dropdown-item"
    >
      <span class="menu-title">
        <feather-icon
          v-if="item.icon"
          :icon="item.icon"
          class="m-0 mr-50"
          size="16"
        />
        {{ t(item.title) }}
      </span>
      <span v-if="item.badge" class="menu-title badge font-smaller" :class="'bg-'+item.badgeColor">{{t(item.badge)}}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useHorizontalNavMenuLink from './useHorizontalNavMenuLink'
import mixinHorizontalNavMenuLink from './mixinHorizontalNavMenuLink'

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('login'))?.login?.userInfo,
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useHorizontalNavMenuLink(props.item)

    const { t } = useI18nUtils()
    const { canViewHorizontalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuLink,

      // i18n
      t,
    }
  },
  methods:{
    verifRole(item){
      if(item.role && item.role=="admin" && !this.userData.roles.includes('Admin')){
        return false
      }else{
        return true
      }
    }
  }
}
</script>
